import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95vh"
          w="100%"
          column
          justifyBetween
          alignCenter
          m="0 auto"
          image={`url(${mobileHero}) center / cover no-repeat`}
        >
          <Header />
          <CFView pulsate zIndex={99} w="100%" column center mb="10%">
            <OrderPickupButton />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          m="0 auto"
          maxWidth="1400px"
          zIndex={90}
          column
          justifyStart
          alignStart
        >
          <CFImage w="100%" src={hero} alt="Kuro Sushi hero text" zIndex={98} />
          <CFView mt="-13%" zIndex={99} ml="12%" pulsate>
            <OrderPickupButton />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
