import React from 'react'
import { Parallax } from 'react-parallax'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promotions } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          w="100%"
          h="550px"
          maxWidth="1400px"
          m="0 auto"
          mt="7%"
          image={`url(${promotions}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
